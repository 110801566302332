.Header {
  margin-top: 10px;
  display: flex;
}

.Teams {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 20px;
  color: white;
}

h4,
h3 {
  margin: 0;
  padding: 0;
}

.Team {
  margin: 0 30px;
  text-align: center;
  padding: 7px 15px;
  border-radius: 10px;
  /* border: 1px solid red; */
  animation: transform 1500ms ease;

  &:hover {
    background-color: #687287;
    cursor: pointer;
    animation: transform 1500ms ease;
    transform: scale(1.1);
  }

  &.SelectedTeam {
    background-color: #687287;
    cursor: pointer;
    animation: transform 1500ms ease;
    transform: scale(1.1);
  }
}

.TeamPlacement.FirstPlace {
  color: #ffd700;
}

.TeamPlacement.SecondPlace {
  color: #c0c0c0;
}

.TeamPlacement.ThirdPlace {
  color: #cd7f32;
}

.TeamPlacement.FourthPlace {
  color: #000000;
}

.TeamPlacement.FifthPlace {
  color: #000000;
}

.TeamPlacement {
    font-size: 42px;
}

.TeamName {
  font-size: 32px;
}

.TeamScore {
  font-size: 28px;
}

.CurrentScores {
  margin-top: 50px;
  text-align: center;
  font-size: 44px;
}

.LastUpdated {
  margin-top: 0px;
  position: relative;
  top: -5px;
  text-align: center;
  font-size: 16px;
}

@media screen and (max-width: 1300px) {

    .Team {
        margin: 0 15px;
    }

    .TeamPlacement {
        font-size: 32px;
    }

    .TeamName {
        font-size: 24px;
    }

    .TeamScore {
        font-size: 18px;
    }
}@media screen and (max-width: 1000px) {

    .Team {
        margin: 0 5px;
    }

    .TeamPlacement {
        font-size: 24px;
    }

    .TeamName {
        font-size: 20px;
    }

    .TeamScore {
        font-size: 16px;
    }
}

@media screen and (max-width: 670px) {

    .Teams {
        flex-direction: column;
    }

    .Team {
        margin: 15px 0;
    }

    .TeamPlacement {
        font-size: 42px;
    }

    .TeamName {
        font-size: 32px;
    }

    .TeamScore {
        font-size: 24px;
    }
}