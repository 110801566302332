.Board {
  text-align: center;
  margin-top: 50px;
  position: relative;
  width: 1500px;
  height: 1000px;
  /* border: 1px solid red; */
  /* width: auto; */
  display: flex;
  transform-origin: top center;

}

@media screen and (max-width: 1525px) {
    .Board {
        transform: scale(0.85);
    }
}@media screen and (max-width: 1270px) {
    .Board {
        transform: scale(0.65);
    }
}@media screen and (max-width: 985px) {
    .Board {
        transform: scale(0.5);
    }
}@media screen and (max-width: 750px) {
    .Board {
        transform: scale(0.4);
    }
}@media screen and (max-width: 600px) {
    .Board {
        transform: scale(0.25);
    }
}@media screen and (max-width: 378px) {
    .Board {
        transform: scale(0.20);
    }
}

.HexBoardTile {
  position: relative;
  -webkit-clip-path: polygon(
    25% 5%,
    75% 5%,
    100% 50%,
    75% 95%,
    25% 95%,
    0% 50%
  );
  clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
  height: 100%;
  width: 100%;
  overflow: visible;
}

div.HexBoardTileParent {
  position: relative;
  height: 150px;
  width: 150px;
  cursor: pointer;
  transform: scale(1);
  -webkit-filter: brightness(1);
  filter: brightness(1);
}

div.HexBoardTileParent.Completed {
    -webkit-filter: brightness(0.3);
    filter: brightness(0.3);
    opacity: 0.5;
}

.HexBoardTileParent:hover .HexBoardTile {
  -webkit-filter: brightness(1.2);
  filter: brightness(1.2);
  transition: all 300ms ease;
  transform: scale(1.1);
}

.hex-image {
    z-index: 999;
}

.HexBoardTile.green {
  background-color: #6c6;
}
.HexBoardTile.yellow {
    background-color: #fc6;
}
.HexBoardTile.pink {
    background-color: #f66;
}

@keyframes rainbow_animation {

    0%,
    100% {
        background-position: 0 0;
    }

    50% {
        background-position: 100% 0;
    }
}

.ChooseTeamPrompt {
    background: linear-gradient(to right, #6666ff, #0099ff, #00ff00, #ff3399, #6666ff);
    color: transparent;
    animation: rainbow_animation 6s ease-in-out infinite;
    margin: 50px 0 0 0;
    padding: 0 20px;
    color: white;
    background-size: 400% 100%;
}

.hex-number {
  color: #000;
  text-shadow: 0 0 5px #fff;
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
}

.hex-info-icon {
  padding-left: 5px;
  display: inline-block;
  width: 16px;
  height: 16px;
}

.hex-images {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.hex-image {
  position: absolute !important;
}

.hex-title {
  color: #fff;
  text-shadow: 0 0 5px #000;
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
}
