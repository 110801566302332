.Title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ClanLogo {
  height: 150px;
  position: relative;
  left: 20px;
  top: 10px;
}

h1 {
  color: #41813f;
}

h1,
h2 {
  margin: 0;
  padding: 0;
}

h3 {
  padding-top: 10px;
}

h1 {
  font-size: 64px;
}

span {
  position: relative;
  top: 10px;
}

.TitleText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 684px) {
    .ClanLogo {
        display: none;
    }
}